<template>
  <section id="">
    <b-tabs
      vertical
      nav-wrapper-class="nav-vertical"
    >
      <b-tab v-for="exercise in data" :key="exercise.id" :title="formatType(exercise.exerciseable_type)">
        <b-card>
          <b-row>
            <b-col cols="5">
              <h4>
                {{ formatType(exercise.exerciseable_type) }}
              </h4>
            </b-col>
          </b-row>
          <b-card-text>
            <strong>Chapter: </strong> {{ exercise.lesson.section.chapter.name }} <strong>| </strong>
            <strong>Section: </strong> {{ exercise.lesson.section.name }} <strong>| </strong>
            <strong>Lesson: </strong> {{ exercise.lesson.name }}
          </b-card-text>
          <h4>Guide</h4>
          <b-row v-if="exercise.exerciseable.guide">
            <b-col :cols="exercise.exerciseable.guide.translation ? '6' : '12'">
              <b-card v-html="exercise.exerciseable.guide.guide"></b-card>
            </b-col>
            <b-col cols="6" v-if="exercise.exerciseable.guide.translation">
              <b-card v-html="exercise.exerciseable.guide.translation.guide"></b-card>
            </b-col>
          </b-row>
          <b-card v-else>
            <b-card-text class="d-flex justify-content-center">No Guide Provided</b-card-text>
          </b-card>
          <hr>
          <h4>Student Exercise</h4>
          <div v-if="exercise.exerciseable_type == 'App\\Models\\GroupExercise'">
            <b-row>
              <b-col :cols="exercise.exerciseable.translation ? '6' : '12'">
                <label for="">Exercise Instruction</label>
                <b-card>
                  {{ exercise.exerciseable.instruction }}
                </b-card>
                <label for="">Exercise Activity</label>
                <b-card v-html="formatHtml(exercise.exerciseable.activity)">
                </b-card>
                <div class="demo-inline-spacing">
                  <span>
                    Student response required?
                  </span>
                  <span v-if="exercise.exerciseable.requires_response">Yes</span>
                  <span v-else>No</span>
                </div>
                <br>
                <label v-if="exercise.exerciseable.requires_response && exercise.exerciseable.expected_response != null">Expected Student Response</label>
                <b-card v-if="exercise.exerciseable.requires_response && exercise.exerciseable.expected_response != null" v-html="exercise.exerciseable.expected_response">
                </b-card>
              </b-col>
              <b-col cols="6" v-if="exercise.exerciseable.translation">
                <label for="">Exercise Instruction Translation</label>
                <b-card>
                  {{ exercise.exerciseable.translation.instruction }}
                </b-card>
                <label for="">Exercise Activity Translation</label>
                <b-card v-html="exercise.exerciseable.translation.activity">
                </b-card>
                <br>
                <label v-if="exercise.exerciseable.requires_response && exercise.exerciseable.translation.expected_response != null">Expected Student Response Translation</label>
                <b-card v-if="exercise.exerciseable.requires_response && exercise.exerciseable.translation.expected_response != null" v-html="exercise.exerciseable.translation.expected_response">
                </b-card>
              </b-col>
            </b-row>
          </div>
          <div v-if="exercise.exerciseable_type == 'App\\Models\\Information'">
            <b-row>
              <b-col :cols="exercise.exerciseable.translation ? '6' : '12'">
                <label for="">Topic</label>
                <b-card>
                  {{ exercise.exerciseable.topic }}
                </b-card>
                <label for="" v-if="exercise.exerciseable.subtopic != ''">Subtopic</label>
                <b-card v-if="exercise.exerciseable.subtopic != ''">
                  {{ exercise.exerciseable.subtopic }}
                </b-card>
                <b-card v-html="formatHtml(exercise.exerciseable.content)">
                </b-card>
              </b-col>
              <b-col cols="6" v-if="exercise.exerciseable.translation">
                <label for="">Topic Translation</label>
                <b-card>
                  {{ exercise.exerciseable.translation.topic }}
                </b-card>
                <label for="" v-if="exercise.exerciseable.translation.subtopic != ''">Subtopic Translation</label>
                <b-card v-if="exercise.exerciseable.translation.subtopic != ''">
                  {{ exercise.exerciseable.translation.subtopic }}
                </b-card>
                <b-card v-html="formatHtml(exercise.exerciseable.translation.content)">
                </b-card>
              </b-col>
            </b-row>
          </div>
          <div v-if="exercise.exerciseable_type == 'App\\Models\\Conversation'">
            <b-row>
              <b-col :cols="exercise.exerciseable.translation ? '6' : '12'">
                <label for="conversation">Conversation</label>
                <b-card v-html="formatHtml(exercise.exerciseable.conversation)">
                </b-card>
              </b-col>
              <b-col cols="6" v-if="exercise.exerciseable.translation">
                <label for="conversation">Conversation Translation</label>
                <b-card v-html="formatHtml(exercise.exerciseable.translation.conversation)">
                </b-card>
              </b-col>
            </b-row>
          </div>
          <div v-if="exercise.exerciseable_type == 'App\\Models\\MatchWord'">
            <b-row>
              <b-col :cols="exercise.exerciseable.translation ? '6' : '12'">
                <b-card
                  title="Instruction"
                >
                  <b-card-text>{{ exercise.exerciseable.instruction }}.</b-card-text>
                </b-card>
                <b-card title="Words">
                  <b-badge
                    v-for="word in exercise.exerciseable.words" :key="word"
                    pill
                    variant="light-secondary"
                    class="text-capitalize"
                  >
                    {{ word }}
                  </b-badge>
                </b-card>
                <b-card title="Options">
                  <b-badge
                    v-for="option in exercise.exerciseable.options" :key="option"
                    pill
                    variant="light-warning"
                    class="text-capitalize"
                  >
                    {{ option }}
                  </b-badge>
                </b-card>
                <b-card title="Answer">
                  <b-badge
                    v-for="answer in exercise.exerciseable.answer" :key="answer"
                    pill
                    variant="light-warning"
                    class="text-capitalize"
                  >
                    {{ answer }}
                  </b-badge>
                </b-card>
              </b-col>
              <b-col cols="6" v-if="exercise.exerciseable.translation">
                <b-card
                  title="Instruction Translation"
                >
                  <b-card-text>{{ exercise.exerciseable.translation.instruction }}.</b-card-text>
                </b-card>
                <b-card title="Words">
                  <b-badge
                    v-for="word in exercise.exerciseable.translation.words" :key="word"
                    pill
                    variant="light-secondary"
                    class="text-capitalize"
                  >
                    {{ word }}
                  </b-badge>
                </b-card>
                <b-card title="Options Translation">
                  <b-badge
                    v-for="option in exercise.exerciseable.translation.options" :key="option"
                    pill
                    variant="light-warning"
                    class="text-capitalize"
                  >
                    {{ option }}
                  </b-badge>
                </b-card>
                <b-card title="Answer(s) Translation">
                  <b-badge
                    v-for="answer in exercise.exerciseable.translation.answer" :key="answer"
                    pill
                    variant="light-warning"
                    class="text-capitalize"
                  >
                    {{ answer }}
                  </b-badge>
                </b-card>
              </b-col>
            </b-row>
          </div>
          <div v-if="exercise.exerciseable_type == 'App\\Models\\Question'">
            <b-row>
              <b-col :cols="exercise.exerciseable.translation ? '6' : '12'">
                <b-card
                  title="Question"
                >
                  <b-card-text>{{ exercise.exerciseable.question }}.</b-card-text>
                </b-card>
                <b-card title="Words">
                  <b-badge
                    v-for="option in exercise.exerciseable.options" :key="option"
                    pill
                    variant="light-secondary"
                    class="text-capitalize"
                  >
                    {{ option }}
                  </b-badge>
                </b-card>
                <b-card title="Answer">
                  <b-badge
                    v-for="answer in exercise.exerciseable.answer" :key="answer"
                    pill
                    variant="light-warning"
                    class="text-capitalize"
                  >
                    {{ answer }}
                  </b-badge>
                </b-card>
              </b-col>
              <b-col cols="6" v-if="exercise.exerciseable.translation">
                <b-card
                  title="Question Translation"
                >
                  <b-card-text>{{ exercise.exerciseable.translation.question }}.</b-card-text>
                </b-card>
                <b-card title="Words Translation">
                  <b-badge
                    v-for="option in exercise.exerciseable.translation.options" :key="option"
                    pill
                    variant="light-secondary"
                    class="text-capitalize"
                  >
                    {{ option }}
                  </b-badge>
                </b-card>
                <b-card title="Answer(s) Translation">
                  <b-badge
                    v-for="answer in exercise.exerciseable.translation.answer" :key="answer"
                    pill
                    variant="light-warning"
                    class="text-capitalize"
                  >
                    {{ answer }}
                  </b-badge>
                </b-card>
              </b-col>
            </b-row>
          </div>
          <hr>
          <h4>Exercise Media</h4>
          <b-row>
            <b-col cols="6">
              <b-card title="Image">
                <div  v-if="exercise.exerciseable.image">
                  <b-img-lazy
                    :src="exercise.exerciseable.image.image"
                    fluid
                    class="w-100"
                  />
                </div>
                <b-card v-else>
                  <b-card-text class="d-flex justify-content-center">No Image Uploaded</b-card-text>
                </b-card>
              </b-card>
            </b-col>
            <b-col cols="6">
              <b-card title="Audio">
                <div v-if="exercise.exerciseable.audio">
                  <b-card>
                      <audio-player
                        ref="audioPlayer"
                        :audio-list="[exercise.exerciseable.audio.audio]"
                        theme-color="#7D71F5"
                        :show-next-button="false"
                        :show-prev-button="false"
                      />
                  </b-card>
                </div>
                <b-card v-else>
                  <b-card-text class="d-flex justify-content-center">No Audio Uploaded</b-card-text>
                </b-card>
              </b-card>
            </b-col>
          </b-row>
          <b-card title="Video">
            <div v-if="exercise.exerciseable.video">
              <b-embed
                type="video"
                aspect="16by9"
                :src="exercise.exerciseable.video.video"
                allowfullscreen
              />
            </div>
            <b-card v-else>
              <b-card-text class="d-flex justify-content-center">No Video Uploaded</b-card-text>
            </b-card>
          </b-card>
        </b-card>
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>
import { BRow, BCol, BCard, BButton, BCardText, BTabs, BTab, BFormRadio, BBadge, BEmbed, BImgLazy } from "bootstrap-vue";
import router from "@/router";
import AudioPlayer from "@liripeng/vue-audio-player";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardText,
    BTabs, 
    BTab,
    BFormRadio,
    BBadge,
    BEmbed,
    BImgLazy,
    AudioPlayer
  },
  data() {
    return {
      data: [],
    };
  },
  async mounted() {
    await this.$http
      .get(
        `admin/teacher/lesson/${router.currentRoute.params.lesson_id}/exercises`
      )
      .then((response) => {
        this.data = response.data.data;
      });
  },
  methods: {
    formatType(type) {
      return type.split('\\')[2];
    },
    formatHtml(string) {
      return string.replace(/(?:\\r\\n|\\r|\\n )/g, '<br/>')
    },
  }
};
</script>
